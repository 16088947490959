import React from 'react';
import PropTypes from 'prop-types';
import htmlParser from 'html-react-parser';
import { navigate } from 'gatsby';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';

import Container from '~components/Container';
import Link from '~components/Link';

import BMPlayButtonIcon from '~images/bar-bat-mitzvah/bm-play-btn.png';
import BMQuizIcon from '~images/bar-bat-mitzvah/quiz-icon.svg';

const useStyles = makeStyles(theme => ({
  bbmpc_watch: {
    fontFamily: "'Roboto', sans-serif",
    padding: '0'
  },
  watch_container: {
    backgroundColor: '#ffffff',
    padding: '40px 40px',
    width: '100%'
  },
  watch_video_wrapper: {
    borderBottom: 'solid #f1eee9 2px',
    paddingBottom: '20px',
    marginBottom: '30px',
    '&:last-child': {
      borderBottom: '0',
      marginBottom: '0'
    }
  },
  thumbnail: {
    textAlign: 'center',
    '& img:hover': {
      opacity: '0.9'
    }
  },
  video_title: {
    fontSize: '18px',
    fontWeight: '500',
    paddingBottom: '8px',
    color: '#5a6175',
    margin: '0',
    '& span': {
      fontSize: '14px',
      fontWeight: '400',
      opacity: '0.5',
      marginLeft: '8px',
      verticalAlign: 'text-bottom'
    }
  },
  video_desc: {
    color: 'rgba(90, 97, 117, 0.7)',
    fontSize: '14px'
  },
  checkmark: {
    textAlign: 'right'
  },
  quiz: {
    backgroundColor: 'rgba(241, 238, 233, 0.5)',
    padding: '10px 16px',
    maxWidth: '60%',
    borderRadius: '2px',
    display: 'flex',
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    }
  },
  quiz_icon: {
    width: '26px',
    height: '32px',
    objectFit: 'contain',
    alignSelf: 'flex-start',
    margin: '3px 16px 0 0'
  },
  quiz_info: {
    flex: '1 1 auto'
  },
  quiz_title: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#5a6175',
    margin: '0'
  },
  quiz_desc: {
    fontSize: '12px',
    color: 'rgba(90, 97, 117, 0.7)',
    margin: '0'
  },
  play_but_wrapper: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    zIndex: '1',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.only('sm')]: {
      transform: 'scale(0.6)'
    }
  },
  color_overlay: {
    position: 'relative',
    '&:before': {
      content: "''",
      display: 'block',
      position: 'absolute',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      background: 'rgba(90, 97, 117, 0.2)',
      MozTransition: 'background 0.3s linear',
      WebkitTransition: 'background 0.3s linear',
      OTransition: 'background 0.3s linear',
      transition: 'background 0.3s linear'
    },
    '&:hover:before': {
      background: 'none'
    }
  },
  spinner: {
    margin: '50px',
    height: '40px',
    width: '40px',
    animation: '$rotate 0.8s infinite linear',
    border: '4px solid #438dce',
    borderRightColor: 'transparent',
    borderRadius: '50%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  },
  buttonStyle: {
    cursor: 'pointer',
    color: '#438dce',
    '&:hover': {
      textDecoration: 'underline',
      opacity: '0.8'
    }
  }
}));

const BMLessonPlanWatch = ({ videos, quizzes }) => {
  const classes = useStyles();
  const handleSubmit = index => {
    navigate('/playlist/bar-bat-mitzvah-preparation', { state: { index } });
  };

  return (
    <div>
      <section id="watch" className={classes.bbmpc_watch}>
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.watch_container}>
                {videos.length === 0 && <div className={classes.spinner} />}
                {videos.map((video, index) => (
                  <div key={video.title} className={classes.watch_video_wrapper}>
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-start"
                      spacing={3}
                    >
                      <Grid item xs={12} sm={3} className={classes.thumbnail}>
                        <div className={classes.color_overlay}>
                          <ButtonBase
                            onClick={() => handleSubmit(index)}
                            className={classes.buttonStyle}
                          >
                            <img
                              src={video.cover_image.url}
                              style={{ width: '100%', display: 'block' }}
                              alt={video.title}
                            />
                            <div className={classes.play_but_wrapper}>
                              <img
                                src={BMPlayButtonIcon}
                                style={{ maxWidth: 62, maxHeight: 62 }}
                                alt="play button"
                              />
                            </div>
                          </ButtonBase>
                        </div>
                      </Grid>
                      <Grid item xs={11} sm={8}>
                        <ButtonBase
                          onClick={() => handleSubmit(index)}
                          className={classes.buttonStyle}
                        >
                          <Typography variant="h6">
                            {index + 1}:{video.title}
                          </Typography>
                        </ButtonBase>
                        <div className={classes.video_desc}>
                          <Typography variant="subtitle2">
                            {htmlParser(video.description.text)}
                          </Typography>
                        </div>
                        {quizzes[index] && (
                          <Link key={quizzes[index].title} to={quizzes[index].link}>
                            <div className={classes.quiz}>
                              <img className={classes.quiz_icon} src={BMQuizIcon} alt="quiz-icon" />
                              <div className={classes.quiz_info}>
                                <Typography component="p" className={classes.quiz_title}>
                                  {quizzes[index].title}
                                </Typography>
                                <Typography component="p" className={classes.quiz_desc}>
                                  Put your knowledge to the test with this fun quiz!
                                </Typography>
                              </div>
                            </div>
                          </Link>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
    </div>
  );
};

BMLessonPlanWatch.propTypes = {
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.shape({
        text: PropTypes.string.isRequired
      }).isRequired,
      cover_image: PropTypes.shape({
        url: PropTypes.string.isRequired
      }).isRequired
    })
  ).isRequired,
  quizzes: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired
    })
  ).isRequired
};

export default BMLessonPlanWatch;

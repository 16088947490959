import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Container from '~components/Container';
import Link from '~components/Link';

import BMStudyIcon from '~images/bar-bat-mitzvah/bar-bat-mitzvah-study-guide.jpg';

const useStyles = makeStyles(theme => ({
  bbmpc_read: {
    fontFamily: "'Roboto', sans-serif",
    padding: '0'
  },
  read_header: {
    // backgroundColor: '#fad188',
    backgroundColor: '#f18744',
    padding: '24px 0'
  },
  header_title: {
    textAlign: 'center',
    color: '#ffffff',
    fontSize: '30px',
    fontWeight: '500',
    textTransform: 'uppercase',
    margin: '0'
  },
  read_container: {
    backgroundColor: '#ffffff',
    padding: '40px 40px',
    color: '#5a6175'
  },
  guide_img_wrapper: {
    textAlign: 'center'
  },
  guide_img: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '50%'
    }
  }
}));

const BMLessonPlanRead = ({ courseGuides }) => {
  const classes = useStyles();
  return (
    <div id="read">
      <section className={classes.bbmpc_read}>
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.read_header}>
                <Typography component="h2" className={classes.header_title}>
                  illustrated course guide
                </Typography>
              </div>
              <div className={classes.read_container}>
                <Grid container direction="row" justify="center" alignItems="center" spacing={6}>
                  <Grid item xs={12} sm={3}>
                    <div className={classes.guide_img_wrapper}>
                      <img
                        src={BMStudyIcon}
                        alt="Bar Bat Mitzvah study guide"
                        className={classes.guide_img}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Typography component="p">
                      Do you prefer to read through the course at your own pace?
                      <br />
                      <br />
                      Want to print it out and read it on Shabbat? <br />
                      <br />
                      <Link to={courseGuides[0].link}>Click here</Link> for our beautiful,
                      illustrated PDF course guide, and get all of the course material at your
                      fingertips.
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
    </div>
  );
};

BMLessonPlanRead.propTypes = {
  courseGuides: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired
    })
  ).isRequired
};

export default BMLessonPlanRead;

import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import classNames from 'classnames';
import { loadCSS } from 'fg-loadcss';

import Container from '~components/Container';

const useStyles = makeStyles(theme => ({
  bbmpc_connect: {
    fontFamily: "'Roboto', sans-serif",
    padding: '0'
  },
  connect_header: {
    backgroundColor: '#8089a7',
    padding: '24px 0',
    width: '100%'
  },
  header_title: {
    textAlign: 'center',
    color: '#ffffff',
    fontSize: '30px',
    fontWeight: '500',
    textTransform: 'uppercase',
    margin: '0'
  },
  connect_container: {
    backgroundColor: '#ffffff',
    padding: '40px 40px',
    textAlign: 'center',
    '& p': {
      color: '#5a6175',
      margin: '0'
    }
  },
  fb: {
    color: '#ffffff',
    backgroundColor: '#3b5998',
    height: '52px',
    padding: '0 60px',
    marginTop: '20px',
    '&:hover': {
      backgroundColor: '#49649e'
    }
  },
  icon: {
    marginRight: theme.spacing(2)
  }
}));

const BMLessonPlanConnect = () => {
  const classes = useStyles();

  useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
      document.querySelector('#font-awesome-css')
    );
  }, []);

  const handleFacebookClick = () => {
    if (typeof window !== 'undefined') {
      window.location.href = 'https://www.facebook.com/groups/2318781628392309';
    }
  };

  return (
    <div>
      <section id="connect" className={classes.bbmpc_connect}>
        <Container>
          <Grid container>
            <div className={classes.connect_header}>
              <Typography component="h2" className={classes.header_title}>
                join the community!
              </Typography>
            </div>
            <div className={classes.connect_container}>
              <Typography component="p">
                Connect with other students and parents through our exclusive Facebook discussion
                group. Share your thoughts about the course, the Bar or Bat Mitzvah process, or
                other related topics. Do you have questions about the material? Interact with our
                own expert educators and the creators of the course!
              </Typography>
              <Button
                variant="contained"
                size="large"
                color="primary"
                className={classes.fb}
                onClick={handleFacebookClick}
              >
                <Icon className={classNames(classes.icon, 'fab fa-facebook')} />
                Join Now
              </Button>
            </div>
          </Grid>
        </Container>
      </section>
    </div>
  );
};

export default BMLessonPlanConnect;

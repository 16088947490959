import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Container from '~components/Container';
import Link from '~components/Link';

import BMLessonPlanImage from '~images/bar-bat-mitzvah/bar-bat-mitzvah-lesson-plan.png';

const useStyles = makeStyles({
  bbmpc_hero: {
    padding: '3rem 1.5rem'
  },
  main_title: {
    fontFamily: "'Raleway', sans-serif",
    color: '#5a6175',
    fontSize: '26px',
    fontWeight: '500',
    margin: '0',
    '& span': {
      color: '#438dce'
    }
  },
  intro_text: {
    color: '#5a6175',
    fontSize: '60px',
    fontWeight: 'bold',
    lineHeight: '60px',
    padding: '8px 0 4px 0',
    margin: '0'
  },
  get_started: {
    color: 'rgba(90, 97, 117, 0.7)',
    fontSize: '26px',
    fontWeight: '500',
    padding: '0 0 16px 0',
    margin: '0'
  },
  get_started_tasks: {
    fontFamily: "'Roboto', sans-serif",
    padding: '0 0 20px 0',
    color: '#438dce'
  },
  task: {
    paddingBottom: '8px',
    '&:last-child': {
      paddingBottom: '0px'
    }
  },
  task_text: {
    marginLeft: '8px',
    '& a': {
      textDecoration: 'underline',
      '&:hover': {
        color: '#438dce',
        textDecoration: 'none',
        opacity: '0.9'
      }
    }
  },
  start_course_btn: {
    fontFamily: "'Roboto', sans-serif",
    padding: '0 60px',
    height: '52px'
  },
  boy_girl_image_wrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    '& img': {
      position: 'absolute',
      top: '0',
      left: '0',
      bottom: '0',
      right: '0',
      width: 'auto',
      height: 'auto',
      maxWidth: '100%',
      maxHeight: '100%',
      margin: 'auto auto -48px'
    }
  },
  button: {
    '&:hover': {
      textDecoration: 'none'
    }
  }
});

const BMLessonPlanHero = ({ welcomeGuides }) => {
  const classes = useStyles();
  return (
    <div>
      <section className={classes.bbmpc_hero}>
        <Container>
          <Grid container>
            <Grid item xs={8}>
              <Typography variant="h4" component="h2" className={classes.main_title}>
                <span>Aleph Beta</span> Bar and Bat Mitzvah Prep Course
              </Typography>
              <div className={classes.intro_text}>Welcome</div>
              <Typography variant="subtitle1" component="p" className={classes.get_started}>
                Let&lsquo;s get started
              </Typography>
              <div className={classes.get_started_tasks}>
                {welcomeGuides.map(({ title, link }) => (
                  <div key={title} className={classes.task}>
                    <span className="icon arrow-icon">
                      <i className="fas fa-arrow-circle-right" />
                    </span>
                    <span className={classes.task_text}>
                      <Link to={link}>{title}</Link>
                    </span>
                  </div>
                ))}
              </div>

              <Button
                component={GatsbyLink}
                to="/playlist/bar-bat-mitzvah-preparation"
                variant="contained"
                size="large"
                color="primary"
                className={classes.button}
              >
                Start Course
              </Button>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.boy_girl_image_wrapper}>
                <img
                  src={BMLessonPlanImage}
                  className="lazyload"
                  alt="Bar Bat Mitzvah lesson plan"
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
    </div>
  );
};

BMLessonPlanHero.propTypes = {
  welcomeGuides: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired
    })
  ).isRequired
};

export default BMLessonPlanHero;

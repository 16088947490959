import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import Container from '~components/Container';

import BMStudyWorkSheetIcon from '~images/bar-bat-mitzvah/bar-bat-mitzvah-study-worksheet.svg';
import BMAudioStudy from '~images/bar-bat-mitzvah/bar-bat-mitzvah-audio-study.svg';
import BMParentsChecklistIcon from '~images/bar-bat-mitzvah/bar-bat-mitzvah-parents-checklist-plan.svg';
import Link from '~components/Link';

const useStyles = makeStyles({
  bbmpc_resources: {
    fontFamily: "'Roboto', sans-serif",
    padding: '0',
    color: '#5a6175',
    '& hr': {
      backgroundColor: '#f1eee9',
      margin: '30px 0',
      height: '2px'
    }
  },
  resources_header: {
    backgroundColor: '#8ed2b0',
    padding: '24px 0',
    width: '100%'
  },
  header_title: {
    textAlign: 'center',
    color: '#ffffff',
    fontSize: '30px',
    fontWeight: '500',
    textTransform: 'uppercase',
    margin: '0'
  },
  header_subtitle: {
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 0.7)',
    fontSize: '20px',
    fontWeight: '500',
    textTransform: 'uppercase',
    margin: '0'
  },
  resources_container: {
    backgroundColor: '#ffffff',
    padding: '40px 40px',
    textAlign: 'left'
  },
  res_title: {
    color: '#5a6175',
    fontSize: '20px',
    fontWeight: '500',
    marginBottom: '20px',
    '& u': {
      textDecoration: 'none',
      borderBottom: '2px solid #5a6175',
      paddingBottom: '4px'
    }
  },
  res_subtitle: {
    color: '#5a6175',
    fontSize: '16px',
    fontWeight: '500',
    margin: '0 0 10px 0'
  },
  res_quote_box: {
    backgroundColor: 'rgba(67, 141, 206, 0.1)',
    padding: '16px 20px',
    display: 'inline-block',
    margin: '10px 0'
  },
  q1: {
    textAlign: 'right'
  },
  q2: {
    fontWeight: '500',
    padding: '4px 0'
  },
  q3: {
    fontSize: '14px'
  },
  res_desc: {
    color: 'rgba(90, 97, 117, 0.8)',
    margin: '0'
  },
  excercise: {
    padding: '20px 0 0 0'
  },
  excercise_icon: {
    width: '50px',
    height: '58px'
  },
  excercise_title: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#5a6175',
    margin: '0'
  },
  excercise_desc: {
    fontSize: '16px',
    color: 'rgba(90, 97, 117, 0.7)',
    margin: '0'
  },
  podcast: {
    padding: '20px 0 30px 0'
  },
  podcast_icon: {
    width: '50px',
    height: '58px'
  },
  podcast_title: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#5a6175',
    margin: '0'
  },
  podcast_desc: {
    fontSize: '16px',
    color: 'rgba(90, 97, 117, 0.7)',
    margin: '0'
  },
  parents_manual: {
    padding: '20px 0 0 0'
  },
  parents_manual_icon: {
    width: '50px',
    height: '58px'
  },
  parents_manual_title: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#5a6175',
    margin: '0'
  },
  parents_manual_desc: {
    fontSize: '16px',
    color: 'rgba(90, 97, 117, 0.7)',
    margin: '0'
  },
  button: {
    '&:hover': {
      textDecoration: 'none'
    }
  }
});

const BMLessonPlanResources = ({ exercises, podcasts, manuals }) => {
  const classes = useStyles();
  return (
    <div>
      <section id="resources" className={classes.bbmpc_resources}>
        <Container>
          <div className={classes.resources_header}>
            <Typography component="h2" className={classes.header_title}>
              mazal tov! you&lsquo;ve completed the course!
            </Typography>
            <Typography component="p" className={classes.header_subtitle}>
              here are your next steps:
            </Typography>
          </div>
          <div className={classes.resources_container}>
            <div className="parents-kids">
              <Typography component="h3" className={classes.res_title}>
                <u>Resources for Parents and Kids</u>
              </Typography>
              <Typography component="h4" className={classes.res_subtitle}>
                Applying What You Learned
              </Typography>

              <Typography component="p" className={classes.res_desc}>
                You’ve learned amazing new things. Now it’s time to put these lessons into practice
                and start applying them in your own life. Here are a few practical exercises to help
                you do exactly that.
              </Typography>
              {exercises.map(({ title, description, link }) => (
                <div key={title} className={classes.excercise}>
                  <Grid container spacing={2}>
                    <Grid item xs={2} sm={1}>
                      <img
                        className={classes.excercise_icon}
                        src={BMStudyWorkSheetIcon}
                        alt="Bar Bat Mitzvah study worksheet"
                        style={{ width: '100%' }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <Typography component="p" className={classes.excercise_title}>
                        {title}
                      </Typography>
                      <Typography component="p" className={classes.excercise_desc}>
                        {description}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button component={Link} to={link} variant="outlined" size="large" fullWidth>
                        Download
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              ))}
              <Divider variant="middle" />
            </div>
            <div className="parents">
              <Typography component="h3" className={classes.res_title}>
                <u>Resources for Parents</u>
              </Typography>
              <Typography component="h4" className={classes.res_subtitle}>
                Podcast
              </Typography>
              <Typography component="p" className={classes.res_desc}>
                Are you wanting something different for you child’s Bar or Bat Mitzvah? Listen to
                this in-depth conversation with the creators of the course, sharing thoughts about
                the standard Bar and Bat Mitzvah experience and ideas about how to keep the focus on
                the true meaning of this day.
              </Typography>
              {podcasts.map(({ title, description, link }) => (
                <div key={title} className={classes.podcast}>
                  <Grid container spacing={2}>
                    <Grid item xs={2} sm={1}>
                      <img
                        className={classes.podcast_icon}
                        src={BMAudioStudy}
                        alt="Bar Bat Mitzvah audio study"
                      />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <Typography component="p" className={classes.podcast_title}>
                        {title}
                      </Typography>
                      <Typography component="p" className={classes.podcast_desc}>
                        {description}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button component={Link} to={link} variant="outlined" size="large" fullWidth>
                        Listen
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              ))}
              <Typography component="h4" className={classes.res_subtitle}>
                Parents&lsquo; Manual
              </Typography>
              <Typography component="p" className={classes.res_desc}>
                Looking for suggestions to maximize the experience of your child&lsquo;s Bar/Bat
                Mitzvah? Download and read our Parent&lsquo;s Manual for ways to shift the focus
                from a day full of pressures to a day full of opportunities.
              </Typography>
              {manuals.map(({ title, description, link }) => (
                <div key={title} className={classes.parents_manual}>
                  <Grid container spacing={2}>
                    <Grid item xs={2} sm={1}>
                      <img
                        className={classes.parents_manual_icon}
                        src={BMParentsChecklistIcon}
                        alt="Bar Bat Mitzvah parents checklist plan"
                      />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <Typography component="p" className={classes.parents_manual_title}>
                        {title}
                      </Typography>
                      <Typography component="p" className={classes.parents_manual_desc}>
                        {description}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Button component={Link} to={link} variant="outlined" size="large" fullWidth>
                        Download
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              ))}
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

BMLessonPlanResources.propTypes = {
  exercises: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired
    })
  ).isRequired,
  podcasts: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired
    })
  ).isRequired,
  manuals: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired
    })
  ).isRequired
};

export default BMLessonPlanResources;
